<template>
    <div id="policy" class="d-flex-c-ac-jfs pt-20">
        <h2 class="py-20">قوانین و مقررات</h2>
        <div class="d-flex-c-ast-jfs-gap10 p-20 l-radius">
            <p>
                شرایط اساسی و اولیه‌ی استفاده کاربران از خدمات<br>
                کاربران موظفند قبل از ثبت‌نام تمامی مفاد این تعهدنامه را
                مطالعه نموده و صرفا در صورت پذیرش آن اقدام به ثبت نام نمایند.
                کاربر می پذیرد که:<br>
                استفاده از خدمات سایت ( ثبت نام ـ خرید ـ فروش و...) به این معنی است که کاربر قوانین را کاملاً خوانده ، فهمیده و قبول کرده و در
                غیر اینصورت، حق استفاده از امکانات سایت را ندارد.<br>
                این حق برای سایت محفوظ است که در هر زمان که بخواهد، با
                اعلام مراتب قبلی به کاربر، مفاد این موافقتنامه را تغییر دهد.<br>
                تمامی معاملات انجام گرفته توسط کاربر صحیح تلقی می‌گردد. از این رو صیانت از حساب کاربری، رمز عبور یا سایر اطلاعات برعهده وی بوده و لازم است به
                صدمات ناشی از انجام تخلفات یا جرایم مالی توجه داشته باشد.<br>
                معاملات ارز های دیجیتال باید با آگاهی و بررسی انجام شود و مسئولیت ضرر نا آگاهانه از خرید ارز های دیجیتال به عهده خود کاربر است. تلاش ما ارائه‌ی خدمات
                به صورت ۲۴ ساعته در طول شبانه روز خواهد بود.لیکن در صورت وقوع حادثه‌ای از قبیل تعلیق خدمات به علت اقدام
                غیر قانونی کاربران یا اشخاص ثالث، یا وقوع خطا در خدمات، مشکل را مرتفع نموده و ارائه‌ی خدمات را از سر
                می‌گیریم.<br>
                در این زمان می‌توانید درخواست بازیابی را بر اساس سوابق معاملاتی ارائه کنید. اما نمی‌توانیم سود
                و زیان ناشی از این وقفه زمانی را جبران کنیم.<br>
                شرایط اساسی و اولیه‌ی استفاده کاربران از خدمات<br>
                کاربران موظفند قبل از ثبت‌نام تمامی مفاد این تعهدنامه را
                مطالعه نموده و صرفا در صورت پذیرش آن اقدام به ثبت نام نمایند.
                کاربر می پذیرد که:<br>
                استفاده از خدمات سایت ( ثبت نام ـ خرید ـ فروش و...) به این معنی است که کاربر قوانین را کاملاً خوانده ، فهمیده و قبول کرده و در
                غیر اینصورت، حق استفاده از امکانات سایت را ندارد.<br>
                این حق برای سایت محفوظ است که در هر زمان که بخواهد، با
                اعلام مراتب قبلی به کاربر، مفاد این موافقتنامه را تغییر دهد.<br>
                تمامی معاملات انجام گرفته توسط کاربر صحیح تلقی می‌گردد. از این رو صیانت از حساب کاربری، رمز عبور یا سایر اطلاعات برعهده وی بوده و لازم است به
                صدمات ناشی از انجام تخلفات یا جرایم مالی توجه داشته باشد.<br>
                معاملات ارز های دیجیتال باید با آگاهی و بررسی انجام شود و مسئولیت ضرر نا آگاهانه از خرید ارز های دیجیتال به عهده خود کاربر است. تلاش ما ارائه‌ی خدمات
                به صورت ۲۴ ساعته در طول شبانه روز خواهد بود.لیکن در صورت وقوع حادثه‌ای از قبیل تعلیق خدمات به علت اقدام
                غیر قانونی کاربران یا اشخاص ثالث، یا وقوع خطا در خدمات، مشکل را مرتفع نموده و ارائه‌ی خدمات را از سر
                می‌گیریم.<br>
                در این زمان می‌توانید درخواست بازیابی را بر اساس سوابق معاملاتی ارائه کنید. اما نمی‌توانیم سود
                و زیان ناشی از این وقفه زمانی را جبران کنیم.<br>
                شرایط اساسی و اولیه‌ی استفاده کاربران از خدمات<br>
                کاربران موظفند قبل از ثبت‌نام تمامی مفاد این تعهدنامه را
                مطالعه نموده و صرفا در صورت پذیرش آن اقدام به ثبت نام نمایند.
                کاربر می پذیرد که:<br>
                استفاده از خدمات سایت ( ثبت نام ـ خرید ـ فروش و...) به این معنی است که کاربر قوانین را کاملاً خوانده ، فهمیده و قبول کرده و در
                غیر اینصورت، حق استفاده از امکانات سایت را ندارد.<br>
                این حق برای سایت محفوظ است که در هر زمان که بخواهد، با
                اعلام مراتب قبلی به کاربر، مفاد این موافقتنامه را تغییر دهد.<br>
                تمامی معاملات انجام گرفته توسط کاربر صحیح تلقی می‌گردد. از این رو صیانت از حساب کاربری، رمز عبور یا سایر اطلاعات برعهده وی بوده و لازم است به
            </p>
        </div>
    </div>
</template>

<script>

    export default {
        name: "Policy",
        components: {},
        data() {
            return {
                data: [
                    {
                        header: 'سوال اول',
                        desc: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.'
                    },
                    {
                        header: 'سوال اول',
                        desc: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.'
                    },
                    {
                        header: 'سوال اول',
                        desc: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.'
                    },
                    {
                        header: 'سوال اول',
                        desc: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.'
                    },
                    {
                        header: 'سوال اول',
                        desc: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.'
                    },
                    {
                        header: 'سوال اول',
                        desc: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.'
                    },
                ]
            }
        }
    }
</script>

<style lang="scss">
    #policy {
        > div {
            background-color: var(--gray-box);
            width: 100%;
            max-width: 1440px;
            text-align: right;
        }
    }
</style>